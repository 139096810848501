// import "./App_base.css";
// import './App_piperwai.css';
// import './App_drloretta.css';
import "./App_yurman.css";
// import './App_shiseido.css';
import React, { useState } from "react";
import logo from "./logo.png";
import { URL } from "./constants";

const LoginPage = ({ onLogin }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [isLoginIssue, setIsLoginIssue] = useState(false);
	const [toastValue, setToastValue] = useState(false);

	const handleLogin = () => {
		if (username.trim() !== "" && password.trim() !== "") {
			fetch(URL + "/authentication/auth/login/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: username,
					password: password,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if ("token" in data) {
						onLogin(username, data["token"]);
					} else {
						showToast("Incorrect username or password");
					}
				});
		}
	};

	const handleSignup = () => {
		if (username.trim() !== "" && password.trim() !== "") {
			fetch(URL + "/authentication/auth/signup/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					username: username,
					password: password,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					console.log(data);
					if ("token" in data) {
						onLogin(username, data["token"]);
					} else if ("username" in data) {
						showToast(data["username"]);
					} else if ("password" in data) {
						showToast(data["password"]);
					} else {
						showToast("There is a problem with this username or password.");
					}
				});
		}
	};

	const showToast = (value) => {
		setIsLoginIssue(true);
		setToastValue(value);
	};

	const goToMarketingSite = () => {
		window.location = "https://tribe.ai/";
	};

	return (
		<div className="login-page-container">
			<div className="login-page-message">Welcome to Jenn!</div>
			<img className="logo" src={logo} onClick={goToMarketingSite} />
			<div className="login-page">
				<input
					className="login-username"
					type="text"
					placeholder="Username"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
					onKeyPress={(e) => (e.key === "Enter" ? handleLogin() : null)}
				/>
				<input
					className="login-password"
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyPress={(e) => (e.key === "Enter" ? handleLogin() : null)}
				/>
				<div>
					<button className="login-submit" onClick={handleLogin}>
						Log in
					</button>
					<button className="signup-submit" onClick={handleSignup}>
						Sign up
					</button>
				</div>
				{isLoginIssue && (
					<div id="login-toast">
						<div id="login-toast-text">{toastValue}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default LoginPage;
