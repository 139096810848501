export const API_POLLING_INTERVAL = 5000;
// export const websiteURL = "http://52.54.144.242:3003";
export const websiteURL = "http://54.210.81.136:3004"; // glamnetic
// export const websiteURL = "http://localhost:3004";

// export const URL = "http://52.54.144.242:1000";
// export const URL = "http://localhost:1000";
// export const URL = "http://3.84.28.155"; // yurman
export const URL = "http://54.210.81.136"; // glamnetic
// export const companyName = 'drloretta';
// export const companyName = 'shiseido';
// export const companyName = 'shiseido_demo';
// export const companyName = 'sokolin';
// export const companyName = 'piperwai';
// export const companyName = 'possible';
// export const companyName = "yurman";
export const companyName = "glamnetic";

export const isOnShopify = true;
export const showReviews = false;
export const cartIsOn = false;
// export const userInputType = "micOnly";
// export const userInputType = "hybrid";
export const userInputType = "textOnly";
export const companyData = {
	olukai: {
		cssFileName: "./App_drloretta.css",
		productInfoFileName: "./product_info__olukai.json",
		shortIntroMessage: "olukai shortIntroMessage",
		longIntroMessage: "olukai longIntroMessage",
		sampleMessageOne: "black hiking shoes under $60",
		sampleMessageTwo: "white beach walking shoes, comfortable",
	},
	drloretta: {
		cssFileName: "./App_drloretta.css",
		productInfoFileName: "./product_info_drloretta.json",
		shortIntroMessage: "drloretta shortIntroMessage",
		longIntroMessage: "drloretta longIntroMessage",
		sampleMessageOne: "",
		sampleMessageTwo: "",
	},
	shiseido: {
		cssFileName: "./App_shiseido.css",
		productInfoFileName: "./product_info_shiseido.json",
		shortIntroMessage: "shiseido shortIntroMessage",
		longIntroMessage: "shiseido longIntroMessage",
		sampleMessageOne: "",
		sampleMessageTwo: "",
	},
	sokolin: {
		cssFileName: "./App_base.css",
		productInfoFileName: "./product_info_sokolin.json",
		shortIntroMessage: "Hi, I'm Dave! 👋 I'm your wine expert and shopping concierge. How can I help you?",
		longIntroMessage: "Hi, I'm Dave! 👋 I'm your wine expert and shopping concierge. How can I help you?",
		sampleMessageOne: "Tuesday night red wine",
		sampleMessageTwo: "Impress my boss, need promotion",
	},
	piperwai: {
		cssFileName: "./App_piperwai.css",
		productInfoFileName: "./product_info_piperwai.json",
		shortIntroMessage:
			"Hi, I'm Jenn! 👋 I'm your pit-care expert and shopping concierge. How can I help you?",
		longIntroMessage:
			"Hi, I'm Jenn! 👋 I'm your pit-care expert and shopping concierge. How can I help you?",
		sampleMessageOne: "Why do my pits smell bad?",
		sampleMessageTwo: "What ingredients are in your deoderant?",
	},
	possible: {
		cssFileName: "./App_possible.css",
		productInfoFileName: "./product_info_possible.json",
		shortIntroMessage:
			"Hi, I am Christian, you POSSIBLE conference concierge! 👋 How can I help you?",
		longIntroMessage:
			"Hi, I am Christian, you POSSIBLE conference concierge! 👋 How can I help you?",
		sampleMessageOne: "When is Gary V speaking?",
		sampleMessageTwo: "What is the best CMO AI event?",
	},
	yurman: {
		cssFileName: "./App_yurman.css",
		productInfoFileName: "./product_info_yurman.json",
		shortIntroMessage: "Hi, I am Jenn, your jewelry expert! 👋 How can I help you?",
		longIntroMessage: "Hi, I am Jenn, your jewelry expert! 👋 How can I help you?",
		sampleMessageOne: "Shop With A Live Product Expert >",
		sampleMessageTwo: "Customer Service >",
	},
	glamnetic: {
		cssFileName: "./App_glamnetic.css",
		productInfoFileName: "./product_info_glamnetic.json",
		shortIntroMessage: "Hi, I am Jenn, your nail and lash expert! 👋 How can I help you?",
		longIntroMessage: "Hey there! 👋 Hi! I'm Jenn, your personal Glamnetic Shopping Assistant. I'm here to help you find and learn more about our products. Is there something specific you're looking for today? For order or other support, please contact us here.",
		sampleMessageOne: "Shop With A Live Beauty Expert >",
		sampleMessageTwo: "Customer Service >",
	},
	base: {
		cssFileName: "./App_base.css",
		productInfoFileName: "./product_info.json",
		shortIntroMessage:
			"Hi, I'm Jenn! 👋 I'm your skincare expert & shopping concierge. How can I help you?",
		longIntroMessage:
			"Hi, I'm Jenn! 👋 I’m your skincare expert & shopping concierge.\n\nWhat can I do for you today? \n\n• A personalized skincare consultation. \n• Answer product questions. \n• Answer more technical skin questions. \n• Help with existing order. \n• Something else.",
		sampleMessageOne: "",
		sampleMessageTwo: "",
	},
};
