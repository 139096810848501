// import './App_base.css';
// import './App_piperwai.css';
// import './App_drloretta.css';
import './App_yurman.css';
// import './App_shiseido.css';
import React, { useState, useEffect, useRef } from 'react';
import Navigation from './Navigation';
// import AudioVisualizer from './AudioVisualizer';
import ChatInterface from './ChatInterface';
import LoginPage from './LoginPage';
import YurmanHome from './homePages/YurmanHome';
// import Products from './Products';
// import QuantityAdjuster from './QuantityAdjusterShopify';
// import CheckoutButton from './CheckoutButtonShopify';
// import VideoPlayer from './VideoPlayer';
import { websiteURL, companyName } from "./constants";
// import shiseido_message_backdrop from './shiseido_message_backdrop.png';
import jenn_avatar from './jenn_avatar.png';
// import shi_video from './ashley_video2.mp4';
// import shi_video from './visualizer2.mov';
// import shi_video from './shiseido_dani_products.mp4';

const App = () => {
  const [username, setUsername] = useState(null);
  const [token, setToken] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [showChat, setShowChat] = useState(false);
  const [isVideo1Playing, setIsVideo1Playing] = useState(true);
  const [isVideo1Active, setIsVideo1Active] = useState(true);
  const video2Ref = useRef(null);
  const [videoIndex, setVideoIndex] = useState(-1);
  // const [videos, setVideos] = useState(['ashley message 1.mp4', 'ashley message 2.mp4', 'ashley message 3.mp4', 'ashley message 4.mp4', 'ashley message 5.mp4', 'ashley message 6.mp4', 'ashley message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda message 1.mp4', 'matilda message 2.mp4', 'matilda message 3.mp4', 'matilda message 4.mp4', 'matilda message 5.mp4', 'matilda message 6.mp4', 'matilda message 7.mp4']);
  // const [videos, setVideos] = useState(['matilda the jenn 1.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const [videos, setVideos] = useState(['the jenn transparent silence.mp4', 'matilda the jenn 2.mp4', 'matilda the jenn 3.mp4', 'matilda the jenn 4.mp4', 'matilda the jenn 5.mp4', 'matilda the jenn 6.mp4', 'matilda the jenn 7.mp4']);
  const videoRefs = useRef(videos.map(() => React.createRef()));
  const [isFullScreen, setIsFullScreen] = useState(false);

  // const handleVideoSwitch = (video) => {
  //   setIsVideo1Active(false);
  // };

  // const handleVideo2End = () => {
  //   setIsVideo1Active(true);
  // };

  // const handleVideoSwitch = () => {
  //     setIsVideo1Active(false);
  //     const nextIndex = (videoIndex + 1) % videos.length;
  //     if(videoIndex != -1){
  //       videoRefs.current[videoIndex].current.style.display = 'none';
  //     }
  //     // videoRefs.current[nextIndex].current.style.display = 'block';
  //     videoRefs.current[nextIndex].current.style.display = 'none';
  //     videoRefs.current[nextIndex].current.play();
  //     setVideoIndex(videoIndex + 1);
  // };

  // const handleVideoEnd = () => {
  //   console.log('video ended');
  //     setIsVideo1Active(true);
  // };

  const handleUser = (user, tok) => {
    console.log(user)
    console.log(tok)
    setUsername(user)
    setToken(tok)
  }

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  const handleFullScreenChange = (isFull) => {
    setIsFullScreen(isFull);
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  if (username === null) {
    return <LoginPage onLogin={handleUser} />;
  } else {
    return (
      <div className="backdrop">
        {/*{<AudioVisualizer audioSrc={shi_video} />}*/}
        {
          companyName !== "yurman" &&
            <iframe 
            src={websiteURL}
            style={{ width: '100%', height: '100vh', border: 'none' }}
            sandbox="allow-scripts allow-same-origin"
            title="External Website"
          />
        }
        {
          companyName === "yurman" &&
            <YurmanHome />
        }
        {/*<img className="message-background" src={shiseido_message_backdrop} />*/}
{/*        <VideoPlayer src={shi_video} />
          <video 
            // src="jenn_silence_30_seconds.mp4"
            src="the jenn silent.mp4"
            className="jenn-avatar-video"
            // autoPlay
            loop
            // style={{ display: isVideo1Active ? 'block' : 'none' }}
            style={{ display: isVideo1Active ? 'none' : 'none' }}
          />
          {videos.map((src, index) => (
              <video
                  key={src}
                  ref={videoRefs.current[index]}
                  className="jenn-avatar-video"
                  src={src}
                  // autoPlay={isVideo1Active === false && index === videoIndex}
                  // style={{ display: isVideo1Active === false && index === videoIndex ? 'block' : 'none' }}
                  style={{ display: isVideo1Active === false && index === videoIndex ? 'none' : 'none' }}
                  onEnded={() => handleVideoEnd()}
              />
          ))}*/}
        <div className={`${showChat && !isFullScreen ? 'chat-interface-wrapper' : ' '}`}>
          {/*<ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} onVideoSwitch={handleVideoSwitch} video2Ref={video2Ref} />*/}
          <ChatInterface username={username} token={token} screenWidth={windowSize[0]} toggleChat={toggleChat} showChat={showChat} isFullScreen={isFullScreen} onFullScreenChange={handleFullScreenChange} />
        </div>
      </div>
    )
  }
};

export default App;

// // To get product info:
// function App() {
//   const [checkoutId, setCheckoutId] = useState(null);

//   const handleQuantityChange = (quantity) => {
//     // Here you might update the application state to reflect the changed quantity,
//     // for example, updating a global cart state or similar.
//   };

//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>My Shopify Store</h1>
//       </header>
//       <Products />
//       <QuantityAdjuster variantId="40595966230606" onQuantityChange={handleQuantityChange} setCheckoutId={setCheckoutId} />
//       <CheckoutButton checkoutId={checkoutId} />
//     </div>
//   );
// }

// export default App;